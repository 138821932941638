'use strict';

import permissionsTypes from '../PermissionsTypes';

const { READ } = permissionsTypes;

const GLOBAL = {
  can: [
    {
      action_list: [READ],
      element: 'PAGE_EMAIL_SIGNATURE_GENERATOR',
    },
    {
      action_list: [READ],
      element: 'PAGE_HOME',
    },
    {
      action_list: [READ],
      element: 'PAGE_SEQUENCE_HOME',
    },
    {
      action_list: [READ],
      element: 'PAGE_CAMPAIGN_HOME',
    },
    {
      action_list: [READ],
      element: 'Ticket',
    },
  ],
  COOKIE: {
    CONNECTED_AS: '_zlk25',
    JWT: 'jwt',
  },
};

export {
  GLOBAL,
};
