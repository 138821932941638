import moment from 'moment';

import {
  interaction_repository,
  sequence_repository,
  search_repository,
} from '@/repositories';

import {
  INTERACTION_CONSTANTS,
  SEQUENCE_RUNNER_STATUS,
  SEQUENCE_STATUS_CONSTANTS,
  SEQUENCE_STEP_TYPE_ID,
} from '@/constants';

const state = () => ({
  contact_target_list: [],
  target_list: [],
  sequence_step_number: 0,
  selected_target_list: [],
  selected_target_id_list: [],
  query_id_list_loaded: [],

  criteria_email_event_list: [],
  criteria_name: '',
  criteria_sequence_id: null,
  criteria_step_position_list: [],
  criteria_targeted_other_sequence: false,
  criteria_target_status_id_list: [],
  criteria_task_status_list: [],

  limit: 20,
  offset: 0,
  page: 1,
  target_count: null,

  process_search_loader: false,
});

const getters = {
  contactTargetList: (state) => state.contact_target_list,
  sequenceStepNumber: (state) => state.sequence_step_number,
  selectedTargetList: (state) => state.selected_target_list,
  selectedTargetIdList: (state) => state.selected_target_id_list,
  targetCount: (state) => state.target_count,
  targetList: (state) => state.target_list,
  queryIdListLoaded: (state) => state.query_id_list_loaded,

  criteriaEmailEventList: (state) => state.criteria_email_event_list,
  criteriaName: (state) => state.criteria_name,
  criteriaSequenceId: (state) => state.criteria_sequence_id,
  criteriaStepPositionList: (state) => state.criteria_step_position_list,
  criteriaTargetedOtherSequence: (state) => state.criteria_targeted_other_sequence,
  criteriaTargetStatusIdList: (state) => state.criteria_target_status_id_list,
  criteriaTaskStatusList: (state) => state.criteria_task_status_list,

  limit: (state) => state.limit,
  page: (state) => state.page,
  offset: (state) => state.offset,

  processSearchLoader: (state) => state.process_search_loader,
};

const actions = {
  getCriteria({ state }) {
    return {
      name: state.criteria_name,
      email_event_list: state.criteria_email_event_list.length > 0
        ? state.criteria_email_event_list
        : undefined,
      step_position_list: state.criteria_step_position_list.length > 0
        ? state.criteria_step_position_list
        : undefined,
      targeted_other_sequence: state.criteria_targeted_other_sequence
        ? state.criteria_targeted_other_sequence
        : undefined,
      target_status_id_list: state.criteria_target_status_id_list.length > 0
        ? state.criteria_target_status_id_list
        : undefined,
      task_status_list: state.criteria_task_status_list.length > 0
        ? state.criteria_task_status_list
        : undefined,
      limit: state.limit,
      offset: state.offset,
    };
  },

  async processSearch({ commit, dispatch }) {
    commit('set_contact_target_list', []);
    commit('set_process_search_loader', true);

    const criteria = await dispatch('getCriteria');
    const target_list = await dispatch('search', criteria);
    const sequences = await dispatch('searchSequences');
    const enriched_contact_target_list = await dispatch('enrichTargets', { target_list, sequences });

    const contact_target_list = enriched_contact_target_list
      .filter((target) => target.contact_details !== undefined)
      .sort((a, b) => b.last_computed_step_update_date - a.last_computed_step_update_date);

    commit('set_contact_target_list', contact_target_list);
    commit('set_process_search_loader', false);
  },

  async enrichTargets({ commit, dispatch, rootState }, { target_list, sequences }) {
    const contact_id_list = target_list.map((target) => (target.contact_id));

    let contact_list = [];
    let sequences_including_contacts = [];

    if (contact_id_list.length > 0) {
      contact_list = await dispatch('getContactDetails', { contact_id_list });
      sequences_including_contacts = await dispatch(
        'searchSequencesIncludingContacts',
        {
          contact_id_list,
        }
      );
    }

    const sequence_step_list = rootState.sequence_detail.sequence_steps;

    commit('set_sequence_step_number', sequence_step_list.length);

    const scheduled_emails = await sequence_repository.searchScheduledEmails({
      organization_id_list: [rootState.user.token_data.organization_id],
      target_id_list: target_list.map((target) => target.id),
    });

    return await Promise.all(target_list
      .map(async (target) => {
        const next_step = await dispatch(
          'determineNextStep',
          {
            scheduled_emails,
            sequence_step_list,
            target,
            sequences,
          });

        const complete_step_list = await dispatch(
          'createCompleteStepList',
          {
            next_step,
            target,
          });

        const contact_details = contact_list.find((contact) => contact.id === target.contact_id);

        const sequences_including_contact = sequences_including_contacts
          .filter((sequence) => sequence.contact_id === target.contact_id);

        const last_computed_step_update_date = await dispatch(
          'calculateLastComputedStepUpdateDate',
          {
            sequence_step_list,
            target,
          }
        );

        return {
          id: target.id,
          contact_id: target.contact_id,
          done_event: target.done_event,
          sequence_id: target.sequence_id,
          status_id: target.status_id,
          contact_details: (contact_details === undefined)
            ? {
              id: target.contact_id,
              contact_info: {
                has_email: false,
              },
            }
            : contact_details,

          complete_step_list,
          last_computed_step_update_date,
          sequences_including_contact,
          is_contact_inactive: (contact_details === undefined),
        };
      })
    );
  },

  async selectAllTargets({ commit, dispatch, state }) {
    const criteria = await dispatch('getCriteria');

    const special_criteria = Object.assign(
      {},
      criteria
    );

    delete special_criteria.limit;
    delete special_criteria.offset;

    const all_target_list = await dispatch('search', special_criteria);
    const all_target_id_list = all_target_list.map((target) => target.id);

    const target_ids_to_select = all_target_id_list
      .filter((target_id) => !state.selected_target_id_list.includes(target_id));

    for (let target of target_ids_to_select) {
      commit('add_target_id_to_selected_target_id_list', target);
    }

    commit('set_selected_target_list', all_target_list);
  },

  async deselectAllTargets({ commit }) {
    commit('set_selected_target_id_list', []);
    commit('set_selected_target_list', []);
  },

  async deleteSelectedTargets({ state, commit }, sequence_id) {
    await sequence_repository.deleteTargets(sequence_id, state.selected_target_id_list);
    commit('set_selected_target_id_list', []);
  },

  async updateSelectedTargets({ commit, dispatch, state }, data) {
    const promise_list = state.selected_target_id_list.map((target_id) => dispatch(
      'updateTarget',
      {
        target_id,
        data,
      }
    ));

    await Promise.allSettled(promise_list);
    commit('set_selected_target_id_list', []);
  },

  // Here is the wrapper around repository search method
  async search({ commit, rootState, state }, criteria) {
    const params = Object.assign({}, criteria);
    const sequence_id = state.criteria_sequence_id;

    let targets;

    delete params.name;

    if (criteria.name.length >= 3) {
      const filtered_contact_response = await search_repository.getContacts({
        contact_quicksearch_list: [criteria.name],
        query_id_list: [rootState.sequence_detail.sequence.query_targets_id],
      });

      const filtered_contact_id_list = filtered_contact_response.contact_list
        .map((contact) => contact.id);

      if (filtered_contact_id_list.length === 0) {
        commit('set_target_count', 0);

        return [];
      }

      params.contact_id_list = filtered_contact_id_list;
    }

    targets = await sequence_repository.getTargets(sequence_id, params);

    const target_list = targets.target_list;

    commit('set_target_count', targets.target_count);

    return target_list;
  },

  async getTargets({ commit }, { sequence_id, criteria }) {
    const response = await sequence_repository.getTargets(sequence_id, criteria);

    commit('set_target_list', response.target_list);
    commit('set_target_count', response.target_count);

    return response.target_list;
  },

  async getContactDetails(_, criteria) {
    const result = await search_repository.getContacts(criteria);

    return result.contact_list;
  },

  async searchInteractions({ rootState }, criteria) {
    const result = await interaction_repository.searchInteractions({
      ...criteria,
      organization_id_list: [rootState.user.token_data.organization_id],
    });

    return result.interaction_list;
  },

  async getSequenceStepList({ state }) {
    const sequence_id = state.criteria_sequence_id;
    const result = await sequence_repository.searchSequenceSteps(sequence_id);

    return result.step_list;
  },

  async getQueryIdListActive(_, id) {
    const result = await search_repository.getWtfContactsActive({
      value_list: [id],
    });

    return result.facet_list.query_id_list;
  },

  async updateTarget({ state, commit }, { target_id, data }) {
    const sequence_id = state.criteria_sequence_id;
    const result = await sequence_repository.updateTarget(sequence_id, target_id, data);

    commit('set_target_status', { target_id, status_id: data.status_id });

    return result;
  },

  async searchSequencesIncludingContacts(_, data) {
    const result = await sequence_repository.searchSequencesIncludingContacts(data);

    return result.sequence_list;
  },

  async searchSequences({ rootState, state }) {
    const sequence_id = state.criteria_sequence_id;
    const organization_id = rootState.user.token_data.organization_id;
    const result = await sequence_repository.searchSequences({
      id_list: [sequence_id],
      organization_id,
    });

    return result.sequence_list;
  },

  async calculateNextSendableDay(_, {date, sequences}) {

    const sendable_days = sequences[0].sendable_days
      .split(',')
      .map((day) => Number(day));

    for (let index = 0; index <= 6; index += 1) {
      const day = moment(date).add(index, 'days');

      if (sendable_days.includes(day.day())) {
        return day.toISOString();
      }
    }

    return date;
  },

  async determineNextStep({ dispatch }, {
    scheduled_emails,
    sequence_step_list,
    target,
    sequences,
  }) {
    if (sequence_step_list.length === 0) {
      return null;
    }

    const target_computed_step_list = target.computed_steps_list;

    const due_date = await dispatch(
      'calculateEmailDueDate',
      {
        scheduled_emails,
        sequence_step_list,
        target,
        sequences,
      });

    if (target_computed_step_list.length === 0) {
      return {
        ...sequence_step_list[0],
        due_date,
      };
    }

    const last_computed_step_id = target_computed_step_list[target_computed_step_list.length - 1]
      .step_id;

    const last_computed_step = sequence_step_list
      .find((sequence_step) => sequence_step.id === last_computed_step_id);

    if (last_computed_step === undefined) {
      return null;
    }

    if (last_computed_step.link_list.length === 0) {
      return null;
    }

    const next_step_id = last_computed_step
      .link_list[0]
      .next_step_id;

    return Object.assign(
      {},
      sequence_step_list
        .find((sequence_step) => sequence_step.id === next_step_id),
      {
        due_date,
      }
    );
  },

  async calculateEmailDueDate( { dispatch }, {
    scheduled_emails,
    sequence_step_list,
    target,
    sequences}) {
    const step_id = sequence_step_list[0].id;

    const target_id = target.id;

    const email_due_date = scheduled_emails.next_steps
      .find((email) => email.target_id === target_id
        && email.step_id === step_id
        && Number(email.status) === SEQUENCE_RUNNER_STATUS.TO_DO.ID
      )
      ?.planned_date;

    if (email_due_date) {
      return await dispatch('calculateNextSendableDay', { date:moment().toISOString(), sequences });
    }

    const target_computed_step_list = target.computed_steps_list;

    if (target_computed_step_list.length === 0) {
      return await dispatch('calculateNextSendableDay', { date:moment().add(1, 'days').toISOString(), sequences });
    }

    const last_computed_step = target_computed_step_list[target_computed_step_list.length - 1];
    const last_computed_step_id = last_computed_step.step_id;

    const last_sequence_step = sequence_step_list
      .find((sequence_step) => sequence_step.id === last_computed_step_id);

    if (last_sequence_step === undefined) {
      return null;
    }

    if (last_sequence_step.link_list.length === 0) {
      return null;
    }

    const last_step_link = last_sequence_step.link_list[0];

    if (last_step_link === undefined) {
      return null;
    }

    if (last_step_link.interval !== undefined) {
      const next_date = moment(last_computed_step.created_at)
        .add(last_step_link.interval, 'days')
        .toISOString();

      if (moment(next_date).isBefore(moment())) {
        return await dispatch('calculateNextSendableDay', { date:moment().add(1, 'days').toISOString(), sequences });
      }

      return await dispatch('calculateNextSendableDay', { date:next_date, sequences });
    }

    return null;
  },

  calculateLastComputedStepUpdateDate(_, { sequence_step_list, target }) {
    const target_computed_step_list = target.computed_steps_list;

    if (
      target_computed_step_list.length > 0
        && target_computed_step_list.length < sequence_step_list.length
    ) {
      const last_computed_step = target_computed_step_list[target_computed_step_list.length - 1];

      return last_computed_step.updated_at;
    }

    return 0;
  },

  createCompleteStepList(_, { next_step, target }) {
    const complete_step_list = [...target.computed_steps_list];

    if (next_step !== null && Object.keys(next_step).length > 0) {
      complete_step_list.push(next_step);
    }

    return complete_step_list.map((step) => {
      let step_type_id;

      if (step.type_id) {
        step_type_id = step.type_id;
      }

      if (step.email_id) {
        step_type_id = SEQUENCE_STEP_TYPE_ID.EMAIL.ID;
      }

      if (step.task_id) {
        step_type_id = SEQUENCE_STEP_TYPE_ID.TASK.ID;
      }

      return Object.assign(
        {},
        step,
        {
          step_type_id,
        }
      );
    });
  },

  async calculateTargetsInteractionCount({ dispatch }, { sequence, criteria }) {
    const targets_interaction_count = {
      appointment_count: 0,
      bounced_count: 0,
      clicked_count: 0,
      opened_count: 0,
      replied_count: 0,
      successful_mail_sent: 0,
    };

    const target_list = await dispatch('getTargets', { sequence_id: sequence.id, criteria});

    const interaction_list = await dispatch(
      'searchInteractions',
      {
        contact_id_list: target_list.map((target) => target.contact_id),
        type_id_list: [INTERACTION_CONSTANTS.APPOINTMENT],
        date_lower_boundary: sequence.start_date,
      }
    );

    target_list.forEach((target) => {
      let target_one_mail_sent = false;

      const interaction_appointment_list = interaction_list
        .filter((interaction) => interaction.contact_id === target.contact_id);

      let appointments_attributable_to_sequence = [];

      if (sequence.status_id === SEQUENCE_STATUS_CONSTANTS.FINISHED.ID) {
        appointments_attributable_to_sequence = interaction_appointment_list
          .filter((interaction) => {
            return moment(interaction.created_at)
              .isBefore(sequence.done_date);
          });
      } else {
        appointments_attributable_to_sequence = interaction_appointment_list
          .filter((interaction) => moment(interaction.created_at)
            .isBefore(moment()));
      }

      const contact_step_count_list = target.computed_steps_list.map((step) => {
        if (step.email_id) {
          if (target_one_mail_sent === false && step.bounced_count === 0) {
            target_one_mail_sent = true;
          }

          return {
            bounced_count: step.bounced_count,
            clicked_count: step.clicked_count,
            opened_count: step.opened_count,
            replied_count: step.replied_count,
          };
        }
      });

      const result = {
        bounced_count: 0,
        clicked_count: 0,
        opened_count: 0,
        replied_count: 0,
      };

      contact_step_count_list
        .filter((step) => step !== undefined)
        .forEach((step) => {
          result.bounced_count += step.bounced_count;
          result.clicked_count += step.clicked_count;
          result.opened_count += step.opened_count;
          result.replied_count += step.replied_count;
        });

      targets_interaction_count.bounced_count += (result.bounced_count > 0 ? 1 : 0);
      targets_interaction_count.clicked_count += (result.clicked_count > 0 ? 1 : 0);
      targets_interaction_count.opened_count += (result.opened_count > 0 ? 1 : 0);
      targets_interaction_count.replied_count += (result.replied_count > 0 ? 1 : 0);
      targets_interaction_count.appointment_count += (
        appointments_attributable_to_sequence.length > 0 ? 1 : 0
      );
      targets_interaction_count.successful_mail_sent += (target_one_mail_sent ? 1 : 0);
    });

    return targets_interaction_count;
  },
};

const mutations = {
  add_target_id_to_selected_target_id_list(state, target_id) {
    state.selected_target_id_list.push(target_id);
  },
  set_contact_target_list(state, contact_target_list) {
    state.contact_target_list = contact_target_list;
  },
  set_target_list(state, target_list) {
    state.target_list = target_list;
  },
  set_criteria_email_event_list(state, criteria_email_event_list) {
    state.criteria_email_event_list = criteria_email_event_list;
  },
  set_criteria_name(state, criteria_name) {
    state.criteria_name = criteria_name;
  },
  set_criteria_sequence_id(state, criteria_sequence_id) {
    state.criteria_sequence_id = criteria_sequence_id;
  },
  set_criteria_step_position_list(state, criteria_step_position_list) {
    state.criteria_step_position_list = criteria_step_position_list;
  },
  set_criteria_targeted_other_sequence(state, criteria_targeted_other_sequence) {
    state.criteria_targeted_other_sequence = criteria_targeted_other_sequence;
  },
  set_criteria_target_status_id_list(state, criteria_target_status_id_list) {
    state.criteria_target_status_id_list = criteria_target_status_id_list;
  },
  set_criteria_task_status_list(state, criteria_task_status_list) {
    state.criteria_task_status_list = criteria_task_status_list;
  },
  set_limit(state, limit) {
    state.limit = limit;
  },
  set_sequence_step_number(state, sequence_step_number) {
    state.sequence_step_number = sequence_step_number;
  },
  set_offset(state, offset) {
    state.offset = offset;
  },
  set_page(state, page) {
    state.page = page;
  },
  set_process_search_loader(state, process_search_loader) {
    state.process_search_loader = process_search_loader;
  },
  set_query_id_list_loaded(state, query_id_list_loaded) {
    state.query_id_list_loaded = query_id_list_loaded;
  },
  set_selected_target_id_list(state, selected_target_id_list) {
    state.selected_target_id_list = selected_target_id_list;
  },
  set_selected_target_list(state, selected_target_list) {
    state.selected_target_list = selected_target_list;
  },
  set_target_count(state, target_count) {
    state.target_count = target_count;
  },
  set_target_status(state, { target_id, status_id }) {
    const target = state.contact_target_list.find((target) => target.id === target_id);

    target.status_id = status_id;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
