<template>
  <header class="header">
    <div class="header__left">
      <div>
        <router-link to="/">
          <img
            class="header__left__logo"
            :src="require('@/assets/images/nomination-logo-white-light.png')"
          >
        </router-link>
      </div>
      <quick-search-element v-if="$can('read', PERMISSIONS.QUICK_SEARCH)" />
    </div>

    <div class="header__right">
      <nav class="header__right__links">
        <div
          v-if="$can('read', PERMISSIONS.ADVANCED_SEARCH)"
          class="header__right__links__link-box"
        >
          <a
            :href="`${configuration.desk_v1_host}/advanced-search`"
            class="header__right__links__link-box__item"
          >
            {{ $t('advanced_search') }}
          </a>
        </div>
        <div
          v-if="$can('read', PERMISSIONS.BUSINESS_ALERTS)"
          class="header__right__links__link-box"
        >
          <a
            :href="`${configuration.desk_v1_host}/business-alerts`"
            class="header__right__links__link-box__item"
          >
            {{ $t('business_alerts') }}
          </a>
        </div>
        <div
          v-if="$can('read', PERMISSIONS.CONFERENCES)"
          class="header__right__links__link-box"
        >
          <a
            :href="`${configuration.desk_v1_host}/conferences`"
            class="header__right__links__link-box__item"
          >
            {{ $t('conferences') }}
          </a>
        </div>
        <div
          v-if="$can('read', PERMISSIONS.NAVBAR_PROSPECTS)"
          class="header__right__links__link-box"
          @mouseover="show_my_prospecting_submenu=true"
          @mouseleave="show_my_prospecting_submenu=false"
        >
          <a
            v-if="!is_licence_insight"
            :href="`${configuration.desk_v1_host}/my-prospecting`"
            class="header__right__links__link-box__item"
          >
            {{ $t('prospecting') }}
          </a>
          <a
            v-if="is_licence_insight"
            :href="`${configuration.desk_v1_host}/my-prospecting/dashboard`"
            class="header__right__links__link-box__item"
          >
            {{ $t('dashboard') }}
          </a>

          <nav
            v-if="show_my_prospecting_submenu"
            class="my-prospecting-sub-header"
            :class="{'my-prospecting-sub-header--right-hide': !can_access_manager}"
          >
            <div class="my-prospecting-sub-header-left">
              <div
                v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_DASHBOARD)"
                class="my-prospecting-sub-header-left-block"
                @click="$filters.goTo(`${configuration.desk_v1_host}/my-prospecting/dashboard`);"
              >
                <span class="my-prospecting-sub-header-icon">
                  <svg viewBox="0 0 20 20">
                    <use :href="require('@/assets/images/dashboard.svg') + '#Desk'" />
                  </svg>
                </span>
                <div class="my-prospecting-sub-header-left-block-description">
                  <span class="my-prospecting-sub-header-left-block-text my-prospecting-sub-header-left-block-text--title">
                    {{ $t('dashboard') }}
                  </span>
                  <span class="my-prospecting-sub-header-left-block-text">
                    {{ $t('dashboard_text') }}
                  </span>
                </div>
              </div>
              <div
                v-if="$can('read', PERMISSIONS.MY_PROSPECTING_MY_PROSPECTS)"
                class="my-prospecting-sub-header-left-block"
                @click="$filters.goTo(`${configuration.desk_v1_host}/my-prospecting/my-prospects`);"
              >
                <span class="my-prospecting-sub-header-icon">
                  <svg viewBox="0 0 20 20">
                    <use :href="require('@/assets/images/contact-search.svg') + '#Desk'" />
                  </svg>
                </span>
                <div class="my-prospecting-sub-header-left-block-description">
                  <span class="my-prospecting-sub-header-left-block-text my-prospecting-sub-header-left-block-text--title">
                    {{ $t('my_prospects') }}
                  </span>
                  <span class="my-prospecting-sub-header-left-block-text">
                    {{ $t('my_prospects_text') }}
                  </span>
                </div>
              </div>
              <div
                v-if="$can('read', PERMISSIONS.MY_PROSPECTING_SMART_SCORING)"
                class="my-prospecting-sub-header-left-block"
                @click="$filters.goTo(`${configuration.desk_v1_host}/my-prospecting/smart-prospect/customers-analysis`)"
              >
                <span class="my-prospecting-sub-header-icon">
                  <svg viewBox="0 0 20 20">
                    <use :href="require('@/assets/images/grow-up.svg') + '#Desk'" />
                  </svg>
                </span>
                <div class="my-prospecting-sub-header-left-block-description">
                  <span class="my-prospecting-sub-header-left-block-text my-prospecting-sub-header-left-block-text--title">
                    {{ $t('smart_scoring') }}
                  </span>
                  <span class="my-prospecting-sub-header-left-block-text">
                    {{ $t('smart_scoring_text') }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="can_access_manager"
              class="my-prospecting-sub-header-right"
            >
              <div
                class="my-prospecting-sub-header-right-first-block"
              >
                <span class="my-prospecting-sub-header-icon">
                  <svg viewBox="0 0 20 20">
                    <use :href="require('@/assets/images/cog.svg') + '#Desk'" />
                  </svg>
                </span>
                <div>
                  <span
                    class="my-prospecting-sub-header-right-first-block-text my-prospecting-sub-header-right-first-block-text--title"
                  >
                    {{ $t('manager_access') }}
                  </span>
                  <a
                    class="my-prospecting-sub-header-link"
                    @click="$filters.goTo(`${configuration.desk_v1_host}/my-prospecting/team-monitoring/synthesis`)"
                  >{{ $t('team_tracking') }}</a>
                  <a
                    class="my-prospecting-sub-header-link"
                    @click="$filters.goTo(`${configuration.desk_v1_host}/my-prospecting/lists-monitoring`)"
                  >{{ $t('prospecting_list') }}</a>
                  <a
                    class="my-prospecting-sub-header-link"
                    @click="$filters.goTo(`${configuration.desk_v1_host}/my-prospecting/licenses-monitoring`)"
                  >{{ $t('licenses') }}</a>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div
          class="header__right__links__link-box"
        >
          <a
            v-if="!is_licence_sender"
            :href="`${configuration.desk_v1_host}/my-lists`"
            class="header__right__links__link-box__item"
          >
            {{ $t('my_lists') }}
          </a>
        </div>
        <div
          v-if="$can('read', PERMISSIONS.CAMPAIGNS) && $can('read', PERMISSIONS.SEQUENCES)"
          class="header__right__links__link-box"
          @mouseover="show_campaigns_sequences_submenu=true"
          @mouseleave="show_campaigns_sequences_submenu=false"
        >
          <div
            class="header__right__links__link-box__item"
          >
            {{ $t('campaigns_and_sequences') }}
          </div>
          <nav
            v-if="show_campaigns_sequences_submenu"
            class="campaigns-sequences-sub-header"
          >
            <div class="campaigns-sequences-sub-header-left">
              <div class="campaigns-sequences-sub-header-left-first-block">
                <span class="campaigns-sequences-sub-header-icon">
                  <svg viewBox="0 0 20 20">
                    <use :href="require('@/assets/icons/bullhorn.svg') + '#Desk'" />
                  </svg>
                </span>
              </div>
              <div>
                <router-link
                  class="campaigns-sequences-sub-header-link campaigns-sequences-sub-header-link--title"
                  to="/campaigns/results"
                >
                  {{ $t('my_campaigns') }}
                </router-link>
                <router-link
                  class="campaigns-sequences-sub-header-link"
                  to="/campaigns/results"
                >
                  {{ $t('assessment') }}
                </router-link>
                <router-link
                  class="campaigns-sequences-sub-header-link"
                  to="/campaigns/lists/sent"
                >
                  {{ $t('lists') }}
                </router-link>
                <router-link
                  v-if="$can('read', PERMISSIONS.CAMPAIGNS_SETTINGS)"
                  class="campaigns-sequences-sub-header-link"
                  to="/campaigns/settings"
                >
                  {{ $t('configuration') }}
                </router-link>
              </div>
            </div>
            <div class="campaigns-sequences-sub-header-right">
              <div class="campaigns-sequences-sub-header-right-first-block">
                <span class="campaigns-sequences-sub-header-icon">
                  <v-icon size="30">
                    mdi-play-circle
                  </v-icon>
                </span>
              </div>
              <div>
                <router-link
                  class="campaigns-sequences-sub-header-link campaigns-sequences-sub-header-link--title"
                  to="/sequences/results"
                >
                  {{ $t('my_sequences') }}
                </router-link>
                <router-link
                  class="campaigns-sequences-sub-header-link"
                  to="/sequences/results"
                >
                  {{ $t('assessment') }}
                </router-link>
                <router-link
                  class="campaigns-sequences-sub-header-link"
                  to="/sequences/lists/ongoing"
                >
                  {{ $t('lists') }}
                </router-link>
                <router-link
                  v-if="$can('read', PERMISSIONS.SEQUENCES_SETTINGS)"
                  class="campaigns-sequences-sub-header-link"
                  to="/sequences/settings"
                >
                  {{ $t('configuration') }}
                </router-link>
              </div>
            </div>
          </nav>
        </div>
      </nav>

      <div
        class="header__right__user"
        @mouseover="show_user_submenu=true"
        @mouseleave="show_user_submenu=false"
      >
        <nav>
          <a>
            <img
              class="header__right__user__avatar"
              :src="require('@/assets/images/dummy/avatar.png')"
            >
          </a>
        </nav>

        <nav
          v-if="user_data !== null"
          class="header__right__user__identity"
        >
          <a
            class="header__right__user__identity__first-name"
          >
            {{ user_data.first_name }}
          </a>
          <a
            class="header__right__user__identity__last-name"
          >
            {{ user_data.last_name }}
          </a>
        </nav>

        <div
          v-if="is_connected_as"
          class="connected-as"
        >
          <v-icon size="24">
            mdi-alert
          </v-icon>
        </div>

        <nav
          v-if="show_user_submenu"
          class="header__submenu submenu-right"
        >
          <a
            v-if="!is_licence_sender"
            class="header__submenu__link"
            :href="`${configuration.desk_v1_host}/personal-space/saved-searches`"
          >
            <span class="header__submenu__link__icon">
              <v-icon size="small">mdi-magnify</v-icon>
            </span>

            <span>{{ $t('user-submenu_link_searches') }}</span>
          </a>

          <div
            v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_ACTIVITY)"
            class="header__submenu__section"
          >
            <span class="header__submenu__section__title">
              {{ $t('user-submenu_title_activity') }}
            </span>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS)"
              class="header__submenu__link"
              :href="`${configuration.desk_v1_host}/personal-space/notifications`"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-bell</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_notifications') }}</span>
            </a>

            <router-link
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_REQUESTS)"
              class="header__submenu__link"
              :to="{ path: '/reports'}"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-binoculars</v-icon>
              </span>
              <span>{{ $t('user-submenu_my_requests_and_inquiries') }}</span>
            </router-link>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_EXPORT)"
              class="header__submenu__link"
              :href="`${configuration.desk_v1_host}/personal-space/exports`"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-download</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_exports') }}</span>
            </a>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION)"
              class="header__submenu__link"
              :href="`${configuration.desk_v1_host}/personal-space/credit-consumption`"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-database</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_credits') }}</span>
            </a>
          </div>

          <div
            v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_PARAMETERS)"
            class="header__submenu__section"
          >
            <span class="header__submenu__section__title">{{ $t('user-submenu_title_configuration') }}</span>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_PROFILE)"
              class="header__submenu__link"
              :href="`${configuration.desk_v1_host}/personal-space/profile`"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-account</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_profile') }}</span>
            </a>
            <router-link
              v-if="user_data.can_see_ai"
              class="header__submenu__link"
              to="/personal-space/my-ai"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-cog</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_ai') }}</span>
            </router-link>
            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_ACCOUNT_SEGMENTS)"
              class="header__submenu__link"
              :href="`${configuration.desk_v1_host}/personal-space/account-segments`"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-office-building</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_companies') }}</span>
            </a>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_CONTACT_SEGMENTS)"
              class="header__submenu__link"
              :href="`${configuration.desk_v1_host}/personal-space/contact-segments`"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-card-account-details-outline</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_contacts') }}</span>
            </a>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_MY_ALERTS)"
              class="header__submenu__link"
              :href="`${configuration.desk_v1_host}/personal-space/alerts`"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-flash</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_alerts') }}</span>
            </a>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_TEMPLATES)"
              class="header__submenu__link"
              :href="`${configuration.desk_v1_host}/personal-space/templates`"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-card-text-outline</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_templates-and-signatures') }}</span>
            </a>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_TEMPLATES) && !is_licence_sender"
              class="header__submenu__link"
              :href="`${configuration.desk_v1_host}/personal-space/bat`"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-at</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_test-emails') }}</span>
            </a>
          </div>

          <div
            v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_MY_ACCOUNT)"
            class="header__submenu__section"
          >
            <span class="header__submenu__section__title">{{ $t('user-submenu_title_account') }}</span>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_EMAIL_CONNECTION)"
              class="header__submenu__link"
              :href="$router.resolve(
                {
                  path: '/personal-space/email-connection'
                })
                .href"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-email</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_email_connection') }}</span>
              <i
                v-if="!nylas_credentials?.email"
                class="fa fa-exclamation-circle email-not-connected"
              />
            </a>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS)"
              class="header__submenu__link"
              :href="`${configuration.desk_v1_host}/personal-space/notifications/settings`"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-bell</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_preferences') }}</span>
            </a>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_CHANGE_PASSWORD)"
              class="header__submenu__link"
              :href="`${configuration.desk_v1_host}/personal-space/change-password`"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-lock</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_password') }}</span>
            </a>

            <a
              v-if="$can('read', PERMISSIONS.PERSONAL_SPACE_LOGOUT)"
              class="header__submenu__link"
              @click="goLogout()"
            >
              <span class="header__submenu__link__icon">
                <v-icon size="small">mdi-power</v-icon>
              </span>
              <span>{{ $t('user-submenu_link_logout') }}</span>
            </a>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import configuration from '@/configurations';
import { mapGetters, mapActions } from 'vuex';
import { PERMISSIONS, LICENSE_CONSTANTS } from '@/constants';
import QuickSearchElement from '@/components/elements/quick_search/QuickSearchElement.vue';

export default {
  name: 'HeaderElement',
  components: {
    QuickSearchElement,
  },
  data: () => ({
    configuration,
    show_campaigns_sequences_submenu: false,
    show_my_prospecting_submenu: false,
    show_user_submenu: false,
    PERMISSIONS,
  }),
  computed: {
    ...mapGetters({
      is_connected_as: 'user/isConnectedAs',
      user_data: 'user/userData',
      nylas_credentials: 'nylas/nylasCredentials',
    }),
    can_access_manager() {
      return this.user_data.is_organization_manager && this.$can('read', PERMISSIONS.MY_PROSPECTING_MANAGER_ACCESS);
    },
    is_licence_insight() {
      // eslint-disable-next-line vue/max-len
      return this.user_data.organization_user_license_list[0].license.id === LICENSE_CONSTANTS.INSIGHT.ID;
    },
    is_licence_sender() {
      // eslint-disable-next-line vue/max-len
      return this.user_data.organization_user_license_list[0].license.id === LICENSE_CONSTANTS.SENDER.ID;
    },
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
    }),
    goLogout() {
      this.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/constants/variables.module.scss';

  .submenu-right {
    right: 0;
  }

  .my-prospecting-sub-header {
    display: flex;
    width: 437px;
    left: 0;
    top: 50px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
    position: absolute;
    background-color: $white;

    &--right-hide {
      width: 220px;
      left: 0;
      position: absolute;
    }
  }

  .my-prospecting-sub-header-icon {
    padding: 8px 0px 10px 15px;
    i {
      color: $blue-haze !important;
    }

    svg {
      fill: $blue-haze;
      width: 40px;
    }
  }

  .my-prospecting-sub-header-left {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .my-prospecting-sub-header-left-block {
    display: flex;
    border-bottom: 1px solid $medium-gray;
    padding-top: 8px;

    &:hover {
      cursor: pointer;
      background-color: $quartz;

      i {
        color: $cornflower-blue;
      }

      svg {
        fill: $cornflower-blue;
      }
    }
  }

  .my-prospecting-sub-header-left-block-description {
    margin-right: 10px;
  }

  .my-prospecting-sub-header-left-block-text {
    align-items: center;
    display: flex;
    color: $blue-zodiac;
    font-size: 10px;
    font-weight: 400;

    &--title {
      margin-top: 5px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .my-prospecting-sub-header-right {
    display: flex;
    border-left: 1px solid $medium-gray;
    border-bottom: 1px solid $medium-gray;
    flex: 1;

    &--hide {
      display: none;
    }

    &:hover {
      background-color: $quartz;

      i {
        color: $cornflower-blue;
      }

      svg {
        fill: $cornflower-blue;
      }
    }
  }

  .my-prospecting-sub-header-right-first-block {
    display: flex;
    padding: 8px 0px;
  }

  .my-prospecting-sub-header-right-first-block-text {
    align-items: center;
    display: flex;
    color: $blue-zodiac;
    font-size: 10px;
    font-weight: 400;

    &--title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .my-prospecting-sub-header-link {
    align-items: center;
    display: flex;
    color: $blue-zodiac;
    font-size: 10px;
    font-weight: 500;
    outline: none;
    text-decoration: none;
    padding-bottom: 5px;

    &--title {
      margin-top: 5px;
      font-size: 12px;
      font-weight: 500;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .campaigns-sequences-sub-header {
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
    position: absolute;
    top: 50px;
    width: 380px;
    display: flex;
  }

  .campaigns-sequences-sub-header-icon {
    margin-top: 25px;
    i {
      color: $blue-haze !important;
    }

    svg {
      fill: $blue-haze;
      width: 30px;
    }
  }

  .campaigns-sequences-sub-header-left {
    display: flex;
    padding: 8px 16px 5px 16px;
    flex: 1;

    &:hover {
      background-color: $quartz;

      i {
        color: $cornflower-blue;
      }

      svg {
        fill: $cornflower-blue;
      }
    }
  }

  .campaigns-sequences-sub-header-left-first-block {
    margin-top: 5px;
  }

  .campaigns-sequences-sub-header-right {
    display: flex;
    border-left: 1px solid $medium-gray;
    padding: 8px 16px 5px 16px;
    flex: 1;

    &:hover {
      background-color: $quartz;

      i {
        color: $cornflower-blue !important;
      }
    }
  }

  .campaigns-sequences-sub-header-right-first-block {
    margin-top: 5px;
  }
  .campaigns-sequences-sub-header-link {
    align-items: center;
    display: flex;
    color: $blue-zodiac;
    font-size: 10px;
    font-weight: 500;
    outline: none;
    text-decoration: none;
    padding-bottom: 5px;

    &--title {
      margin-top: 5px;
      font-size: 12px;
      font-weight: 500;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .campaigns-sequences-sub-header-icon {
    margin-right: 12px;
    text-align: center;
  }

  .header {
    align-items: center;
    background-color: $space-cadet;
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding: 0 15px;
    position: relative;
    z-index: map-get($zindex, header);

    &__submenu {
      background-color: $white;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
      padding: 10px;
      position: absolute;
      top: 50px;
      width: 300px;

      &__link {
        align-items: center;
        color: $amethyst-smoke;
        display: flex;
        font-size: 12px;
        font-weight: 500;
        padding: 8px;
        outline: none;
        text-decoration: none;

        &__icon {
            margin-right: 12px;
            text-align: center;
        }

        &:deep(i) {
          color: $amethyst-smoke !important;
        }

        &:deep(.email-not-connected) {
          color: $ripe-pumpkin !important;
        }

        &:hover {
          border-right: 3px solid $cornflower-blue;
          color: $haiti;

            &:deep(i) {
              color: $cornflower-blue !important;
            }

            &:deep(.email-not-connected) {
              color: $ripe-pumpkin !important;
            }
        }
      }

      &__section {
        margin-top: 10px;

        &__title {
          color: $blue-haze;
          font-size: 12px;
          letter-spacing: 2px;
          margin-bottom: 8px;
          margin-left: 0px;
          text-transform: uppercase;
        }
      }
    }

    &__left {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &__logo {
        margin-right: 5px;
        width: 29px;
      }

      &__tagline {
        color: $white;
        font-size: 12px;
        margin: auto;
      }
    }

    &__right {
        align-items: center;
        display: flex;
        justify-content: space-between;

      &__links {
        align-items: center;
        display: flex;
        justify-content: space-between;

        &__link-box {
          align-items: center;
          display: flex;
          height: 50px;
          justify-content: center;
          position: relative;

          &:hover {
            border-top: 2px solid $space-cadet;
            border-bottom: 2px solid $white;
          }

          &__item {
            color: $heather;
            font-size: 12px;
            padding: 8px;
            text-decoration: none;
            transition: color 0.2s linear;

            &:hover {
                color: $white;
            }
          }
        }
      }

      &__user {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 50px;
        margin-left: 10px;

        &:hover {
          border-top: 2px solid $space-cadet;
          border-bottom: 2px solid $white;
        }

        &__avatar {
          align-items: center;
          display: flex;
          border-radius: 50%;
          margin-right: 10px;
          width: 30px;
        }

        &__identity {
          align-items: left;
          display: flex;
          flex-direction: column;
          font-size: 12px;
          justify-content: space-between;

          &__first-name {
            color: $white;
            text-decoration: none;
          }

          &__last-name {
            color: $white;
            text-decoration: none;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .connected-as {
    margin-left: 10px;

    .v-icon {
      color: $burnt-sienna;
      font-size: 30px;
    }
  }

  .link-with-icon {
    display: flex;
    justify-content: space-between;
  }

  .email-not-connected {
    margin-left: 100px;
  }
</style>

<i18n src="@/locales/components/elements/header-element.json"></i18n>
