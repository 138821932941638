'use strict';

const CAMPAIGN_NAME_MAX_LENGTH = 65;
const CAMPAIGN_NAME_MIN_LENGTH = 1;
const CAMPAIGN_PER_PAGE_COUNT = 20;
const CAMPAIGN_TARGET_PER_PAGE_COUNT = 10;
const CAMPAIGN_CREDIT_VALUE_PER_TARGET = 0.5;
const CAMPAIGN_EMAIL_SUBJECT_MIN_LENGTH = 3;
const CAMPAIGN_EMAIL_HTML_CONTENT_MIN_LENGTH = 17;

const CAMPAIGN_STATISTICS = {
  AVERAGE_RATE: {
    CLICKERS: 2.6,
    DELIVERED: 95,
    OPENERS: 16.5,
  },
};

const CAMPAIGN_STATUS = {
  DRAFT: {
    ID: 1,
    NAME: 'draft',
  },
  SCHEDULED: {
    ID: 2,
    NAME: 'scheduled',
  },
  SENT: {
    ID: 3,
    NAME: 'sent',
  },
};

const CAMPAIGN_SETTING_CARD_HEADER = [
  {
    size: 4,
    value: 'Nom',
  },
  {
    size: 5,
    value: 'Adresse de l\'expéditeur',
  },
  {
    size: 3,
    value: 'Adresse de réponse',
  },
];

const CAMPAIGN_UNSUBSCRIBE_VARIABLE = '$Desabonnement$';

const CAMPAIGN_SCHEDULE_OPTION = {
  NOW: 1,
  SCHEDULE: 2,
};

const EMPTY_HTML_VALUE = '<!DOCTYPE html><html><head><title></title></head><body></body></html>';

const NEW_CAMPAIGN_MIN_ID = 50000;

export {
  CAMPAIGN_CREDIT_VALUE_PER_TARGET,
  CAMPAIGN_NAME_MAX_LENGTH,
  CAMPAIGN_NAME_MIN_LENGTH,
  CAMPAIGN_PER_PAGE_COUNT,
  CAMPAIGN_STATISTICS,
  CAMPAIGN_TARGET_PER_PAGE_COUNT,
  CAMPAIGN_SETTING_CARD_HEADER,
  CAMPAIGN_STATUS,
  CAMPAIGN_UNSUBSCRIBE_VARIABLE,
  CAMPAIGN_EMAIL_SUBJECT_MIN_LENGTH,
  CAMPAIGN_EMAIL_HTML_CONTENT_MIN_LENGTH,
  CAMPAIGN_SCHEDULE_OPTION,
  EMPTY_HTML_VALUE,
  NEW_CAMPAIGN_MIN_ID,
};
