export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        
      },
      "fr": {
        "answer_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse sous 2 jours ouvrés !"])},
        "attach_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre un fichier"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "contact_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire de contact"])},
        "file_too_big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre fichier doit faire moins de 3 MB"])},
        "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer un fichier (3 MB max)"])},
        "mandatory_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez écrire un message"])},
        "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone : 01 82 69 69 16"])},
        "request_type_bug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un bug"])},
        "request_type_profile_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un paramétrage de mon profil"])},
        "request_type_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter le service client"])},
        "request_type_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander une formation"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message a bien été envoyé"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
        "your_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message"])},
        "your_need": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre besoin"])}
      }
    }
  })
}
