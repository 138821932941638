<template>
  <v-app>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      class="progress-linear"
      top
      color="primary"
      fixed
    />

    <v-main>
      <div class="main-container">
        <header-element />

        <div class="content">
          <div class="sidebar">
            <personal-space-sidebar-element />
          </div>

          <div class="main">
            <slot />
          </div>

          <div
            class="overlay-hidden"
            :class="{'overlay-active': showing_quick_search_results}"
          />
        </div>

        <div class="footer">
          <footer-element />
          <div
            class="overlay-hidden"
            :class="{'overlay-active': showing_quick_search_results}"
          />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { useIntercom } from '@homebaseai/vue3-intercom';
import { mapGetters, mapActions } from 'vuex';
import configuration from '@/configurations';
import defineAbilitiesFor from '@/plugins/ability';

import FooterElement from '@/components/elements/FooterElement';
import HeaderElement from '@/components/elements/HeaderElement';
import PersonalSpaceSidebarElement from '@/components/elements/PersonalSpaceSidebarElement';

export default {
  name: 'PersonalSpaceLayout',
  components: {
    FooterElement,
    HeaderElement,
    PersonalSpaceSidebarElement,
  },
  setup() {
    return {
      intercom: useIntercom(),
    };
  },
  computed: {
    ...mapGetters({
      loading: 'app_layout/isLoading',
      showing_quick_search_results: 'app_layout/showingQuickSearchResults',
      user_data: 'user/userData',
    }),
  },
  async mounted() {
    const updatedAbilities = defineAbilitiesFor(this.user_data);

    this.$ability.update(updatedAbilities.rules);

    const [
      license_name,
      organization_status_name,
      status_name,
    ] = await Promise.all([
      this.getLicenseName(this.user_data),
      this.getOrganizationById(this.user_data.organization.id)
        .then((organization) => this.getOrganizationStatusName(organization)),
      this.getStatusName(this.user_data),
    ]);

    const initIntercom = () => {
      this.intercom.boot({
        app_id: configuration.intercom_app_id,
        email: this.user_data.email,
        name: `${this.user_data.first_name} ${this.user_data.last_name}`,
        user_id: this.user_data.id,
        Licence: license_name,
        StatutOrga: organization_status_name,
        StatutUser: status_name,
        Phone: this.user_data.phone,
        Mobile: this.user_data.mobile_phone,
      });
    };

    if (configuration.intercom_widget_authorized_url.includes(window.location.host)) {
      initIntercom();
    }
  },
  methods: {
    ...mapActions({
      getLicenseName: 'user/getLicenseName',
      getOrganizationById: 'user/getOrganizationById',
      getStatusName: 'user/getStatusName',
      getOrganizationStatusName: 'user/getOrganizationStatusName',
    }),
  },
};
</script>

<style lang="scss" scoped>
  @import '@/constants/variables.module.scss';

  .progress-linear {
    z-index: map-get($zindex, progress_linear);
  }

  .content {
    display: flex;
    min-height: calc(100vh - 100px);
    position: relative;
  }

  .footer {
    position: relative;
  }

  .sidebar {
    border-right: 1px solid $medium-gray;
    padding: 0 0 0 24px;
    width: 260px;
  }

  .main {
    flex: 7;
  }

  .overlay-hidden {
    display: none;
  }

  .overlay-active {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: map-get($zindex, quick_search_overlay);
  }
</style>
