<template>
  <template v-if="icon === 'ai'">
    <svg
      viewBox="0 0 14 14"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/ai.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'arrow-right'">
    <svg
      viewBox="0 0 11 10"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/arrow-right.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'briefcase'">
    <svg
      viewBox="0 0 15 12"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/briefcase.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'building'">
    <svg
      viewBox="0 0 14 12"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/building.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'check'">
    <svg
      viewBox="0 0 13 12"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/check.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'chevron-up'">
    <svg
      viewBox="0 0 14 8"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/chevron-up.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'close'">
    <svg
      viewBox="0 0 8 8"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/close.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'disclaimer'">
    <svg
      viewBox="0 0 12 12"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/disclaimer.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'education'">
    <svg
      viewBox="0 0 15 14"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/education.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'flash'">
    <svg
      viewBox="0 0 13 15"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/flash.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'handshake'">
    <svg
      viewBox="0 0 14 10"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/handshake.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'help'">
    <svg
      viewBox="0 0 14 14"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/help.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'info'">
    <svg
      viewBox="0 0 28 28"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/info.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'mail'">
    <svg
      viewBox="0 0 15 12"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/mail.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'paper-plane'">
    <svg
      viewBox="0 0 12 12"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/paper-plane.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'pen-writing'">
    <svg
      viewBox="0 0 18 18"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/pen-writing.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'phone'">
    <svg
      viewBox="0 0 13 12"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/phone.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'plus'">
    <svg
      viewBox="0 0 13 12"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/plus.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'search'">
    <svg
      viewBox="0 0 12 13"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/search.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'signature'">
    <svg
      viewBox="0 0 14 14"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/signature.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'star'">
    <svg
      viewBox="0 0 16 16"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/star.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'trophy'">
    <svg
      viewBox="0 0 12 12"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/trophy.svg') + '#Icon'" />
    </svg>
  </template>

  <template v-else-if="icon === 'user'">
    <svg
      viewBox="0 0 13 12"
      :width="size"
    >
      <use :href="require('@/assets/icons/nds/user.svg') + '#Icon'" />
    </svg>
  </template>
</template>

<script>
export default {
  name: 'SvgElement',
  props: {
    icon: String,
    size: [Number, String],
  },
};
</script>
